import { IEntity, IEntityResponse, IGetOptionalRequest, IState } from '../storeTypes'
import { IUser } from '../auth/authTypes'
import { IOwner } from "../uniqueVulns/uniqueVulnsTypes"

export enum VulnsTypes {
  GET = 'vulns/getVulns',
  GET_ALL = 'vulns/getAllVulns',
  GET_ONE = 'vulns/getVulnById',
  GET_TEMPLATE = 'vulns/getVulnTemplate',
  GET_TEMPLATES = 'vulns/getVulnTemplates',
  GET_VULNS_PROJECTS = 'vulns/getVulnsProjects',
  POST = 'vulns/createVuln',
  CREATE_TEMPLATE = 'vulns/createVulnTemplates',
  DELETE = 'vulns/deleteVuln',
  PATCH = 'vulns/changeVuln',
  UPLOAD = 'vulns/uploadVulnScreenshots',
  GET_SCREENSHOTS = 'vulns/getVulnScreenshots',
  DELETE_SCREENSHOTS = 'vulns/deleteVulnScreenshots',
  DELETE_SCREENSHOT = 'vulns/deleteVulnScreenshot',
  UPDATE_SCREENSHOT_DESCRIPTION = 'vulns/updateVulnScreenshotDescription',
  UPLOAD_SCREENSHOT_DESCRIPTION = 'vulns/uploadVulnScreenshotDescription',

}

interface IVulnErrors {
  location_error: string
  vulnerability_name_error: string
  description_error: string
  cause_of_occurrence_error: string
  procedure_exploiting_error: string
  recommendation_error: string
  negative_consequences_error: string
  cve_id_error: string
  cwe_id_error: string
  attack_vector_error: string
  attack_complexity_error: string
  privileges_required_error: string
  user_interaction_error: string
  scope_error: string
  confidentiality_error: string
  integrity_error: string
  availability_error: string
}

export enum CvssPRTypes {
  N = 'PR:N',
  L = 'PR:L',
  H = 'PR:H',
}

export enum CvssSTypes {
  U = 'S:U',
  C = 'S:C',
}


export interface IVuln extends IEntity {

  name: string | null
  location: string | null
  description: string | null
  cause_of_occurrence?: string | null
  negative_consequences: string[] | null
  cvss_score: number | string | null
  cvss_vector: string | null
  risk_level: string | null
  procedure_exploiting: string | null
  recommendations: string | null
  cwe_id?: string[] | null
  cve_id?: string[] | null
  owner?: IOwner
  my_scope?: boolean
  screenshots?: {
    description: string
    vulnerability_id: string
    id: string
  }[]
  template_id?: string
  status?: string | null
  created_at?: string
  path?: string | null
  is_killchain?: boolean
  killchain_id?: string | null
}


export interface IVulnMini {
  id: string
  name: string | null
  status: string | null
  description: string | null
  created_at: string
  updated_at: string
  risk_level: string
  owner: {
    id: string
    first_name: string
    email: string
  }
}

export interface IGetVulnTemplateResponse {
  id: string
  tpl_name: string
  name: string | null
  cve_id: string | null
  cwe_id: string | null
  location: string | null
  description: string | null
  cause_of_occurrence: string | null
  negative_consequences: string | null
  cvss_score: string | null
  cvss_vector: string | null
  risk_level: string | null
  procedure_exploiting: string | null
  recommendations: string | null
  additional_info: string | null
  object_type: string | null
  status: string | null
  is_delete: boolean | null
  owner: any | null
  path: string | null
}

export interface IVulnTemplates {
  id: string
  tpl_name: string
}

export interface IScreenshotData {
  file: any
  description: string
  sequence: number
  base64data: string
}

export interface IScreenshots {
  [objectId: string]: {
    [fileName: string]: IScreenshotData
  }
}



export interface IVulnsState extends IState, IEntityResponse {
  vulns: IVulnMini[]
  newVulnId: string
  allVulns: IVuln[]
  vulnById: IVuln
  vulnScreenshots?: IGetScreenshotsList[]
  templateScreenshots: IScreenshots
  errors: IVulnErrors
  selectTab: string
  templates: IVulnTemplates[]
  vulnsSendStatus: 'pending' | 'resolve' | 'reject' | null,
  vulnTemplate: IGetVulnTemplateResponse | null

}

export interface ICreateVulnRequestTemplate {
  objectId: string
  projectId: string
  objectType: string
  template: IVuln
  screenshotData: { [fileName: string]: IScreenshotData }
}

export interface IUploadScreenshotsAndDescriptionRequest {
  projectId: string
  objectType: string
  objectId: string
  vulnId: string
  screenshotData: { [fileName: string]: IScreenshotData }
}



export interface IVulnRequest extends IGetOptionalRequest {
  objectId: string
  projectId: string
  objectType: string
}

export interface ICreateVulnSendData {
  name: string
  cve_id: string[]
  cwe_id: string[]
  location: string
  description: string
  cause_of_occurrence: string
  negative_consequences: string
  cvss_score: number
  cvss_vector: string
  risk_level: string
  procedure_exploiting: string
  recommendations: string
  additional_info: string
  status: string
  is_killchain?: boolean
  killchain_id?: string
  screenshots: {
    imgbase64: string
    description: string
  }[]
}

export interface ICreateVulnRequest extends IVulnRequest {
  vuln: ICreateVulnSendData
}

export interface IDeleteVulnRequest extends IVulnRequest {
  vulnId: string
}

export interface IDeleteVulnRequest extends IVulnRequest {
  vulnId: string
}

export interface IChangeVulnRequest extends IDeleteVulnRequest {
  vuln: IVuln
}



export interface IVulnResponse extends IEntityResponse {
  vuln: IVuln
}

export interface IGetVulnResponse extends Pick<IState, 'count'> {
  data: IVuln[]
}

export interface IGetVulnResponseMini extends Pick<IState, 'count'> {
  data: IVulnMini[]
}

export interface IGetVulnTemplatesResponse extends Pick<IState, 'count'> {
  data: IVulnTemplates[]
}




export interface IDeleteVulnResponse extends IEntityResponse {
  id: string
}

export interface IGetScreenshotsList {
  id: string
  description: string
  sequence: number
}



export interface IUploadScreenshotsRequest extends IDeleteVulnRequest {
  files: {
    imagbase64: string
    sequence: number
    description: string
  }[]

}

export interface IUploadScreenshotsResponse {
  id: string
  vulnerability_id: string
}

export interface IDeleteScreenshotRequest extends IDeleteVulnRequest {
  screenId: string
  fullScreenId: string
  sequence?: number
}

export interface ICreateVulnsForObjectsResponse {
  objectsList: {
    id: string
    type: string
  }[],
  dataValue: IVuln
  projectId: string
  screenshots: IScreenshots
}

export interface ICreateVulnsForObjectsSendData {
  template_id: string
  name: string
  cve_id: string[]
  cwe_id: string[]
  location: string
  description: string
  cause_of_occurrence: string
  negative_consequences: string
  cvss_score: number
  cvss_vector: string
  risk_level: string | null
  procedure_exploiting: string
  recommendations: string
  additional_info: string
  is_delete: false
  status: 'new'
  object_id: string
  object_type: string
  screenshots: {
    imgbase64: string
    description: string
  }[]
}

export interface ICreateVulnsForObjectsResponseData {
  vulns: ICreateVulnsForObjectsSendData[]
  variables: {
    PATH: string | null
  }
}

export interface IProjectsVulns {
  id: string
  name: string
  risk_level: string
  cvss_score: string
  count_object: number
  description: string
  owner: {
    id: string
    first_name: string
    email: string
  }
  object_type: string
  object_id: string
  project_id: string
  create_id: string
  created_at: string
}

export interface IProjectsVulnsRequest {
  projectId: string
  pagination: {
    offset: string | number
    limit: string | number
  }
  sortParam?: string
  filters?: string
}
export interface IProjectsVulnsResponse {
  count: number
  data: IProjectsVulns[]
}
