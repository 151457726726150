import { EMPTY_ERROR_MESSAGE, GENERAL_ERROR_MESSAGES } from "../../../constants/errors"
import {
  setDesktopAppNameError,
  setDesktopAppInfSystemError,
} from "../../../store/objects/objectsSlice"
import { IDesktopApp } from "../../../store/objects/desktopApps/desktopAppsTypes"

/**
 * return is the data valid
 * @param {IDesktopApp} object  Validation data
 * @param {any} dispatch        Dispatcher to change values in the store
 * @return {boolean}            Is the data valid
 */
export const validateDesktopApp = (
  object: IDesktopApp,
  dispatch: any,
): boolean => {
  let isAppNameCorrect

  let isInfSystemError

  if (object.inf_system?.id === "") {
    isInfSystemError = false
    dispatch(setDesktopAppInfSystemError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY))
  }
  else {
    isInfSystemError = true
    dispatch(setDesktopAppInfSystemError(""))
  }

  if (!object.app_name) {
    dispatch(setDesktopAppNameError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY))

    isAppNameCorrect = false
  } else {
    dispatch(setDesktopAppNameError(EMPTY_ERROR_MESSAGE))

    isAppNameCorrect = true
  }




  return (isAppNameCorrect && isInfSystemError)
}
