import { ChangeEvent, FC, useEffect, useState } from 'react'

import styles from '../../Items.module.scss'
import Navbar from '../../../components/navbar/Navbar'
import Pagination from '../../../components/pagination/Pagination'
import Filters from '../../../components/filters/Filters'
import Button from '../../../components/button/Button'
import { TextVariantEnum } from '../../../components/text/TextTypes'
import { selectProjects } from '../../../store/projects/projectsSelectors'
import CreateProject from '../../../components/modals/projects/CreateProject'
import { getProjects } from '../../../store/projects/projectsAsync'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import Loader from '../../../components/loader/Loader'
import Tabs from '../../../components/tabs/Tabs'
import { ILink } from '../../../components/tabs/TabsTypes'
import { InputTypeEnum } from '../../../components/input/InputTypes'
import { IFilter } from '../../../components/filters/FiltersTypes'
import { dateChange } from '../../../utils/dateChange'
import { IProject } from '../../../store/projects/projectsTypes'
import { selectProfileData } from '../../../store/auth/authSelectors'
import ExportModal from '../../../components/modals/general/export/ExportModal'
import { ExportTypes } from '../../../utils/export/ExportTypes'
import Notification from '../../../components/notification/Notification'

import { filterDateOrNumber } from '../../../utils/prepare/filterDateOrNumber'
import { localization } from '../../../localization/localization'

import { DEFAULT_OFFSET_PAGINATION } from '../../../constants/pages'
import { getNextSortParam, getSortString, sortListType, TableComponent } from '../../../shared'
import { getDefaultSortParams } from '../../../shared/lib'
import { prepareProjectStatusToRu, projectHeader } from '../../../constants/projects'

import { exportProjects } from './utils/exportProjects'


/**
 * Component for displaying information on projects page.
 *
 */
const ProjectsPage: FC = () => {
  const dispatch = useAppDispatch()

  const { role } = useAppSelector(selectProfileData)

  const {
    projects,
    isLoading,
    error,
    status,
    count,

  } = useAppSelector(selectProjects)

  const [projectFilters, setProjectFilters] = useState<IProject>({
    functional_customer: '',
    end_date: '',
    gos_order_date: '',
    gos_order_number: '',
    name: '',
    start_date: '',
    status: '',
    is_delete: false,
  })

  const [filters, setFilters] = useState<string>('')
  const [isCreateModal, setCreateModal] = useState<boolean>(false)
  const [isExportModal, setExportModal] = useState<boolean>(false)
  const [selectTab, setSelectTab] = useState<string>('active')

  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [gosOrderDate, setGosOrderDate] = useState<Date | null>(null)

  const [secondaryStartDate, setSecondaryStartDate] = useState<Date | null>(null)
  const [secondaryEndDate, setSecondaryEndDate] = useState<Date | null>(null)
  const [secondaryGosOrderDate, setSecondaryGosOrderDate] = useState<Date | null>(null)

  const [projectsTabLinks, setProjectsTabLinks] = useState<ILink[]>([])
  const [prepareSecondaryStartDate, setPrepareSecondaryStartDate] = useState<string>('')
  const [prepareSecondaryEndDate, setPrepareSecondaryEndDate] = useState<string>('')
  const [prepareSecondaryGosOrderDate, setPrepareSecondaryGosOrderDate] = useState<string>('')
  const [pagination, setPagination] = useState<{ offset: number, limit: number }>({ offset: 0, limit: DEFAULT_OFFSET_PAGINATION })


  //useEffect(() => {
  // dispatch(getProjects({ pagination }));
  //}, [dispatch, pagination]);

  const onTabClick = (name: string) => setSelectTab(name)
  const onCreateProjectHandler = () => setCreateModal(prevState => !prevState)
  const onExportProjectHandler = () => setExportModal(prevState => !prevState)

  const onStartDateChange = (date: any) => {
    dateChange(date, setStartDate, setProjectFilters, projectFilters, 'start_date')
  }
  const onEndDateChange = (date: any) => {
    dateChange(date, setEndDate, setProjectFilters, projectFilters, 'end_date')
  }
  const onGosDateChange = (date: any) => {
    dateChange(date, setGosOrderDate, setProjectFilters, projectFilters, 'gos_order_date')
  }

  const onSecondaryStartDateChange = (date: any) => {
    dateChange(date, setSecondaryStartDate, setPrepareSecondaryStartDate)
  }
  const onSecondaryEndDateChange = (date: any) => {
    dateChange(date, setSecondaryEndDate, setPrepareSecondaryEndDate)
  }
  const onSecondaryGosOrderDateChange = (date: any) => {
    dateChange(date, setSecondaryGosOrderDate, setPrepareSecondaryGosOrderDate)
  }

  const onSearchButtonClick = (reset: boolean) => {
    let filtersString = ''

    Object.entries(projectFilters).forEach((projectValue: any) => {
      if (projectValue[1]) {
        filtersString += `${filtersString ? '&' : ''}${projectValue[0]}=${projectValue[1]}`
      }
    })

    filtersString = filterDateOrNumber(filtersString, projectFilters, prepareSecondaryStartDate, 'start_date')
    filtersString = filterDateOrNumber(filtersString, projectFilters, prepareSecondaryEndDate, 'end_date')
    filtersString = filterDateOrNumber(filtersString, projectFilters, prepareSecondaryGosOrderDate, 'gos_order_date')

    if (reset) {
      filtersString = ''

      setProjectFilters({
        end_date: '',
        gos_order_date: '',
        gos_order_number: '',
        name: '',
        start_date: '',
        is_delete: false,
      })

      setStartDate(null)
      setEndDate(null)
      setGosOrderDate(null)
      setSecondaryStartDate(null)
      setSecondaryEndDate(null)
      setSecondaryGosOrderDate(null)

      setPrepareSecondaryStartDate('')
      setPrepareSecondaryEndDate('')
      setPrepareSecondaryGosOrderDate('')
      setFilters('')
      // dispatch(getProjects({ filters: '', pagination }));
    }

    if (filtersString && !reset) {
      setFilters(filtersString)
      // dispatch(getProjects({ filters, pagination }));
    }
  }

  const onPageClick = (offset: number, limit: number) => {
    setPagination({ offset, limit })
  }





  const filterItems: IFilter[] = [
    {
      id: 1,
      text: localization.project.filters.nameText,
      placeholder: localization.project.filters.namePlaceholder,
      type: InputTypeEnum.Text,
      value: projectFilters.name,
      onChange: (event: ChangeEvent<HTMLInputElement>) => setProjectFilters({
        ...projectFilters,
        name: event.target.value,
      }),
    },
    {
      id: 2,
      text: localization.project.filters.startDateText,
      type: InputTypeEnum.Date,
      date: startDate,
      onDateChange: onStartDateChange,
      secondaryValue: secondaryStartDate,
      onSecondaryChange: onSecondaryStartDateChange,
      primaryText: localization.common.primaryDateTextHelper,
      secondaryText: localization.common.secondaryDateTextHelper,
    },
    {
      id: 3,
      text: localization.project.filters.endDateText,
      type: InputTypeEnum.Date,
      date: endDate,
      onDateChange: onEndDateChange,
      secondaryValue: secondaryEndDate,
      onSecondaryChange: onSecondaryEndDateChange,
      primaryText: localization.common.primaryDateTextHelper,
      secondaryText: localization.common.secondaryDateTextHelper,
    },
    {
      id: 4,
      text: localization.project.filters.gosOrderNumberText,
      placeholder: localization.project.filters.gosOrderNumberPlaceholder,
      type: InputTypeEnum.Text,
      value: projectFilters.gos_order_number,
      onChange: (event: ChangeEvent<HTMLInputElement>) => setProjectFilters({
        ...projectFilters,
        gos_order_number: event.target.value,
      }),
    },
    {
      id: 5,
      text: localization.project.filters.gosOrderDateText,
      type: InputTypeEnum.Date,
      date: gosOrderDate,
      onDateChange: onGosDateChange,
      secondaryValue: secondaryGosOrderDate,
      onSecondaryChange: onSecondaryGosOrderDateChange,
      primaryText: localization.common.primaryDateTextHelper,
      secondaryText: localization.common.secondaryDateTextHelper,
    },
    {
      id: 6,
      text: localization.common.deleteFilterText,
      type: InputTypeEnum.Checkbox,
      value: projectFilters.is_delete,
      onChange: (event: ChangeEvent<HTMLInputElement>) => setProjectFilters({
        ...projectFilters,
        is_delete: event.target.checked,
      }),
    },
  ]






  const [sortParams, setSortParams] = useState<sortListType>(getDefaultSortParams(projectHeader))


  const handleClickSort = (param: string) => {
    setSortParams(getNextSortParam(param, sortParams))
  }

  useEffect(() => {
    const activeTab = selectTab === 'all' ? '' : '&status=' + selectTab
    const filterString = filters + activeTab


    dispatch(getProjects({ filters: filterString, pagination, sortParams: getSortString(sortParams) }))
  }, [dispatch, sortParams, selectTab, pagination, filters])

  useEffect(() => {

    const tabs = role === "client"
      ? [
        {
          name: 'Активные',
          tabId: 'active',
        },
        {
          name: 'Завершенные',
          tabId: 'completed',
        },
        {
          name: 'Планируемые',
          tabId: 'planned',
        },
        {
          name: localization.project.tabs.allTitle,
          tabId: 'all',
        },
      ]
      : [
        {
          name: 'Активные',
          tabId: 'active',
        },
        {
          name: 'Завершенные',
          tabId: 'completed',
        },
        {
          name: 'Планируемые',
          tabId: 'planned',
        },
        {
          name: 'Загруженный',
          tabId: 'loaded',
        },
        {
          name: localization.project.tabs.allTitle,
          tabId: 'all',
        },
      ]
    setProjectsTabLinks(tabs)

  }, [role])


  return (
    <>
      <Navbar />
      <div className={styles.items}>
        {isLoading ? <Loader /> : (
          <>
            <div className={styles['items-content']}>
              <Filters filters={filterItems} onSearchButtonClick={onSearchButtonClick} />
              <Tabs links={projectsTabLinks} tabActive={selectTab} onClick={onTabClick} />
              <div className={styles['items-departures']}>
                {(role === 'admin' || role === 'manager' || role === 'chief') && (
                  <Button
                    onClick={onCreateProjectHandler}
                    buttonText={localization.project.createButtonText}
                    typeButtonText={TextVariantEnum.S}
                  />
                )}
                <Button
                  onClick={onExportProjectHandler}
                  buttonText={localization.common.exportTableButtonText}
                  typeButtonText={TextVariantEnum.S}
                />
              </div>
            </div>
            <div className={styles['items-table-wrapper']}>
              <TableComponent
                sortParams={sortParams}
                handleClickSort={handleClickSort}
                setSelectedRows={() => { }}
                headersRow={projectHeader}
                dataList={
                  projects?.map(
                    project => ({
                      ...project,
                      status: project ? prepareProjectStatusToRu[project.status || ''] : '',
                    }))}
              />
            </div>
          </>
        )}
        <Pagination onPageClick={onPageClick} count={count} startLimit={pagination.limit} />
        {(role === 'admin' || role === 'manager' || role === 'chief') && isCreateModal && (
          <CreateProject isModalVisible={isCreateModal} setModalVisible={setCreateModal} />

        )}
        <ExportModal
          isModalVisible={isExportModal}
          setModalVisible={setExportModal}
          handlePdfExport={() => exportProjects(ExportTypes.PDF, projects)}
          handleDocxExport={() => exportProjects(ExportTypes.DOCX, projects)}
          handleExcelExport={() => exportProjects(ExportTypes.EXCEL, projects)}
        />
        {(status !== 200 && status !== 202 && status !== 203 && status !== 205 && status !== 206 && status !== 207) && (
          <Notification status={status} error={error} title={localization.project.notificationProjectTitle} />
        )}
      </div>
    </>
  )
}

export default ProjectsPage
