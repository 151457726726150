import { EMPTY_ERROR_MESSAGE, GENERAL_ERROR_MESSAGES } from "../../../constants/errors"
import {
  setMobileAppNameError,
  setMobileAppInfSystemError,
} from "../../../store/objects/objectsSlice"
import { IMobileApp } from "../../../store/objects/mobileApps/mobileAppsTypes"

/**
 * return is the data valid
 * @param {IMobileApp} object  Validation data
 * @param {any} dispatch       Dispatcher to change values in the store
 * @return {boolean}           Is the data valid
 */
export const validateMobileApp = (
  object: IMobileApp,
  dispatch: any,
): boolean => {
  let isAppNameCorrect

  let isInfSystemsCorrect

  if (object.inf_system?.id === "") {
    isInfSystemsCorrect = false
    dispatch(setMobileAppInfSystemError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY))
  }
  else {
    isInfSystemsCorrect = true
    dispatch(setMobileAppInfSystemError(""))
  }

  if (!object.app_name) {
    dispatch(setMobileAppNameError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY))

    isAppNameCorrect = false
  } else {
    dispatch(setMobileAppNameError(EMPTY_ERROR_MESSAGE))

    isAppNameCorrect = true
  }


  return (isAppNameCorrect && isInfSystemsCorrect)
}
