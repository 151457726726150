import { GENERAL_ERROR_MESSAGES } from "../../../constants/errors"
import {
  setWebAppInfSystemError,
  setWebAppDomainNameError,
} from "../../../store/objects/objectsSlice"
import { IWebApp } from "../../../store/objects/webApps/webAppTypes"


export const validateWebApp = (
  object: IWebApp,
  dispatch: any,
): boolean => {
  let isDomainNameCorrect
  const isInfSystemCorrect = object.inf_system?.id !== ""

  if (!object.domain_name) {
    isDomainNameCorrect = false
    dispatch(setWebAppDomainNameError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY))
  }
  else {
    isDomainNameCorrect = true
    dispatch(setWebAppDomainNameError(""))
  }

  if (isInfSystemCorrect) {
    dispatch(setWebAppInfSystemError(""))
  } else {
    dispatch(setWebAppInfSystemError(GENERAL_ERROR_MESSAGES.POPUP_EMPTY))
  }

  return (isDomainNameCorrect && isInfSystemCorrect)
}
