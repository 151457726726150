import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { isError } from '../storeHelpers'

import { IVuln, IVulnMini, IVulnsState } from './vulnsTypes'
import {
  changeVuln,
  createVuln,
  createVulnTemplates,
  deleteVuln,
  deleteVulnScreenshot,
  deleteVulnScreenshots,
  getAllVulns,
  getVulnById,
  getVulns,
  getVulnScreenshots,
  getVulnTemplate,
  getVulnTemplates,
  updateVulnScreenshotsDescription,
  uploadScreenshotsAndDescriptions,
  uploadVulnScreenshots,
} from './vulnsAsync'
import { prepareRiskLevelToRu } from "../../constants/vulns"

const initialState: IVulnsState = {
  vulns: [],
  newVulnId: '',
  allVulns: [],
  vulnById: {
    location: '',
    description: '',
    cause_of_occurrence: '',
    recommendations: '',
    cvss_score: 0,
    cvss_vector: '',
    risk_level: '',
    procedure_exploiting: '',
    name: '',
    negative_consequences: [],
    screenshots: [],
  },
  templates: [],
  templateScreenshots: {},
  vulnsSendStatus: null,
  vulnTemplate: null,
  errors: {
    location_error: '',
    description_error: '',
    cause_of_occurrence_error: '',
    recommendation_error: '',
    procedure_exploiting_error: '',
    vulnerability_name_error: '',
    negative_consequences_error: '',
    cve_id_error: '',
    cwe_id_error: '',

    attack_vector_error: '',
    attack_complexity_error: '',
    privileges_required_error: '',
    user_interaction_error: '',
    scope_error: '',
    confidentiality_error: '',
    integrity_error: '',
    availability_error: '',
  },
  isLoading: false,
  error: null,
  status: null,
  count: 0,
  selectTab: '',
}

export const vulnsSlice = createSlice({
  name: 'vulns',
  initialState,
  reducers: {
    setTemplateScreenshot: function (state, action: PayloadAction<{
      objectId: string
      fileName: string
      image: any
      sequence?: number
    }>) {
      if (state.templateScreenshots[action.payload.objectId] === undefined) {
        state.templateScreenshots[action.payload.objectId] = {}
      }
      const { image, fileName, objectId, sequence } = action.payload

      state.templateScreenshots[objectId][fileName] = {
        base64data: '',
        description: '',
        file: image,
        sequence: sequence === undefined ? Object.keys(state.templateScreenshots[objectId]).length + 1 : sequence,
      }
    },

    setScreenshotDescription(state, action: PayloadAction<{ description: string; fileName: string; objectId: string }>) {
      const { description, fileName, objectId } = action.payload
      state.templateScreenshots[objectId][fileName].description = description
    },
    setScreenshotBase64Data(state, action) {
      const { base64Data, fileName, objectId } = action.payload
      state.templateScreenshots[objectId][fileName].base64data = base64Data
    },
    setScreenshotSequence(state, action) {
      const { objectId, fileName, sequence } = action.payload
      state.templateScreenshots[objectId][fileName].sequence = sequence
    },
    deleteScreenshot(state, action) {
      const { objectId, fileName } = action.payload
      delete state.templateScreenshots[objectId][fileName]
    },

    setVulnSelectTab(state, action) {
      state.selectTab = action.payload
    },

    setVulnLocationError(state, action) {
      state.errors.location_error = action.payload
    },
    setVulnDescriptionError(state, action) {
      state.errors.description_error = action.payload
    },
    setVulnCauseOccurrenceError(state, action) {
      state.errors.cause_of_occurrence_error = action.payload
    },
    setVulnRecommendationError(state, action) {
      state.errors.recommendation_error = action.payload
    },
    setVulnProcedureExploitingError(state, action) {
      state.errors.procedure_exploiting_error = action.payload
    },
    setVulnNameError(state, action) {
      state.errors.vulnerability_name_error = action.payload
    },
    setVulnNegativeConsequencesError(state, action) {
      state.errors.negative_consequences_error = action.payload
    },
    setCveIdError(state, action) {
      state.errors.cve_id_error = action.payload
    },
    setCweIdError(state, action) {
      state.errors.cwe_id_error = action.payload
    },

    setVulnAttackVectorError(state, action) {
      state.errors.attack_vector_error = action.payload
    },
    setVulnAttackComplexityError(state, action) {
      state.errors.attack_complexity_error = action.payload
    },
    setVulnPrivilegesRequiredError(state, action) {
      state.errors.privileges_required_error = action.payload
    },
    setVulnUserInteractionError(state, action) {
      state.errors.user_interaction_error = action.payload
    },
    setVulnScopeError(state, action) {
      state.errors.scope_error = action.payload
    },
    setVulnConfidentialityError(state, action) {
      state.errors.confidentiality_error = action.payload
    },
    setVulnIntegrityError(state, action) {
      state.errors.integrity_error = action.payload
    },
    setVulnAvailabilityError(state, action) {
      state.errors.availability_error = action.payload
    },
    clearVulnIsAdded(state) {
      state.vulnsSendStatus = null
    },
    resetTemplateScreenshots(state) {
      state.templateScreenshots = {}
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getVulnTemplates.fulfilled, (state, action) => {
        state.isLoading = false
        state.templates = action.payload.data
      })
      .addCase(getVulnTemplates.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.status = null
      })
      .addCase(getVulnTemplate.fulfilled, (state, action) => {
        state.isLoading = false
        state.vulnTemplate = action.payload
      })
      .addCase(getVulnTemplate.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.status = null
      })
      .addCase(getVulns.fulfilled, (state, action) => {
        state.isLoading = false
        state.vulns = action.payload.data.map(vuln => {
          return { ...vuln, created_at: new Date(vuln.created_at).toLocaleDateString() }
        })

        state.count = action.payload.count
      })
      // createVulnTemplates

      .addCase(getVulns.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.status = null
      })
      .addCase(getAllVulns.fulfilled, (state, action) => {
        state.isLoading = false
        state.allVulns = action.payload.data

        state.count = action.payload.count
      })
      .addCase(createVuln.fulfilled, (state, action) => {
        state.isLoading = false
        state.count += 1
        state.status = action.payload.status
        const newVuln = action.payload.vuln
        if (newVuln.id) {
          state.newVulnId = newVuln.id
        }

        state.vulns.push({
          id: newVuln.id ? newVuln.id : '',
          status: newVuln.status ? newVuln.status : '',
          description: newVuln.description,
          name: newVuln.name,
          created_at: newVuln.created_at ? new Date(newVuln.created_at).toLocaleDateString() : '',
          updated_at: newVuln.created_at ? new Date(newVuln.created_at).toLocaleDateString() : '',
          risk_level: newVuln.risk_level == null ? "info" : prepareRiskLevelToRu[newVuln.risk_level],
          owner: {
            id: newVuln.owner?.id || '',
            first_name: newVuln.owner?.first_name || '',
            email: newVuln.owner?.email || '',
          },
        })
        // state.vulns.length < 10 && state.vulns.push(action.payload.vuln);
      })
      .addCase(createVuln.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.status = null
        state.newVulnId = ''
      })
      .addCase(createVulnTemplates.fulfilled, (state) => {
        state.vulnsSendStatus = 'resolve'
      })
      .addCase(createVulnTemplates.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.status = null
        state.vulnsSendStatus = 'pending'
      })
      .addCase(changeVuln.fulfilled, (state, action) => {
        state.isLoading = false

        state.vulns.forEach((vuln: IVulnMini) => {
          if (vuln.id === action.payload.vuln.id) {
            vuln.name = action.payload.vuln.name

          }
        })

        state.allVulns.forEach((vuln: IVuln) => {
          if (vuln.id === action.payload.vuln.id) {
            vuln.name = action.payload.vuln.name
            vuln.procedure_exploiting = action.payload.vuln.procedure_exploiting
            vuln.negative_consequences = action.payload.vuln.negative_consequences
            vuln.recommendations = action.payload.vuln.recommendations
            vuln.description = action.payload.vuln.description
            vuln.cause_of_occurrence = action.payload.vuln.cause_of_occurrence
            vuln.location = action.payload.vuln.location
            vuln.cve_id = action.payload.vuln.cve_id
            vuln.cwe_id = action.payload.vuln.cwe_id
            vuln.cvss_score = action.payload.vuln.cvss_score
            vuln.cvss_vector = action.payload.vuln.cvss_vector
            vuln.risk_level = action.payload.vuln.risk_level
          }
        })

        state.status = action.payload.status
        state.vulnById = action.payload.vuln
      })
      .addCase(changeVuln.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.status = null
      })
      .addCase(deleteVuln.fulfilled, (state, action) => {
        state.isLoading = false
        state.count -= 1

        state.status = action.payload.status
        state.vulns = state.vulns.filter((vuln: IVulnMini) => vuln.id !== action.payload.id)
        state.allVulns = state.allVulns.filter((vuln: IVuln) => vuln.id !== action.payload.id)
      })
      .addCase(deleteVuln.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.status = null
      })
      .addCase(getVulnById.fulfilled, (state, action) => {
        state.isLoading = false
        state.vulnById = action.payload
      })
      .addCase(getVulnById.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.status = null
      })

      .addCase(uploadVulnScreenshots.fulfilled, (state) => {
        state.isLoading = false

        window.location.reload()
      })
      .addCase(uploadScreenshotsAndDescriptions.pending, (state) => {
        state.status = null
      })
      .addCase(uploadScreenshotsAndDescriptions.fulfilled, (state, action) => {
        state.status = 200
        state.vulnScreenshots?.push(...action.payload)
        state.templateScreenshots = {}
      })
      .addCase(updateVulnScreenshotsDescription.pending, (state) => {
        state.status = null
      })
      .addCase(updateVulnScreenshotsDescription.fulfilled, (state, action) => {
        state.status = 200
        state.vulnScreenshots?.forEach((el, index) => {
          if (state.vulnScreenshots && el.id === action.payload.id) {
            state.vulnScreenshots[index].description = action.payload.description
          }
        })

      })
      .addCase(getVulnScreenshots.fulfilled, (state, action) => {
        state.isLoading = false
        state.vulnScreenshots = action.payload
      })
      .addCase(deleteVulnScreenshots.fulfilled, (state) => {
        state.isLoading = false

        state.vulnScreenshots = []
      })
      .addCase(deleteVulnScreenshots.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.status = null
      })
      .addCase(deleteVulnScreenshot.fulfilled, (state, action) => {
        state.isLoading = false
        state.vulnScreenshots = state.vulnScreenshots?.filter((screenshot) => screenshot.id !== action.payload)
      })
      .addCase(deleteVulnScreenshot.pending, (state) => {
        state.isLoading = true
        state.error = null
        state.status = null
      })
      .addMatcher(isError, (state, action) => {
        state.error = action.payload
        state.status = action.payload
        state.isLoading = false
      })
  },
})

export const {
  resetTemplateScreenshots,
  setVulnSelectTab,
  setTemplateScreenshot,
  setScreenshotBase64Data,
  setScreenshotDescription,
  setScreenshotSequence,
  deleteScreenshot,
  setVulnLocationError,
  setVulnDescriptionError,
  setVulnCauseOccurrenceError,
  setVulnRecommendationError,
  setVulnNameError,
  setVulnProcedureExploitingError,
  setVulnNegativeConsequencesError,
  setCveIdError,
  setCweIdError,
  clearVulnIsAdded,
  setVulnAvailabilityError,
  setVulnIntegrityError,
  setVulnConfidentialityError,
  setVulnScopeError,
  setVulnUserInteractionError,
  setVulnPrivilegesRequiredError,
  setVulnAttackComplexityError,
  setVulnAttackVectorError,
} = vulnsSlice.actions

export default vulnsSlice.reducer
