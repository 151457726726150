import { EMPTY_ERROR_MESSAGE, GENERAL_ERROR_MESSAGES } from "../../../constants/errors"
import {
  setArmAddressIpError,
  setArmGroupingError,
  setArmNetWorkDeviceNameError,
} from "../../../store/objects/objectsSlice"
import { validateIp } from "../general/validateIp"
import { IArm } from "../../../store/objects/arm/armTypes"

export const validateArm = (
  object: IArm,
  dispatch: any,
): boolean => {
  let isAddressIpCorrect
  let isNetworkDeviceNameCorrect
  let isGroupingError

  const isAddressIpFormatCorrect = validateIp(object.ip_address)

  if (object.ip_address && !isAddressIpFormatCorrect) {
    dispatch(setArmAddressIpError(GENERAL_ERROR_MESSAGES.IP))

    isAddressIpCorrect = false
  } else {
    dispatch(setArmAddressIpError(EMPTY_ERROR_MESSAGE))

    isAddressIpCorrect = true
  }

  if (!object.network_device_name) {
    dispatch(setArmNetWorkDeviceNameError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY))

    isNetworkDeviceNameCorrect = false
  } else {
    dispatch(setArmNetWorkDeviceNameError(EMPTY_ERROR_MESSAGE))

    isNetworkDeviceNameCorrect = true
  }

  if (object.inf_system?.id === "" && object.office?.id === "") {
    dispatch(setArmGroupingError(GENERAL_ERROR_MESSAGES.POPUP_EMPTY))
    isGroupingError = true
  }
  else {
    isGroupingError = false
    dispatch(setArmGroupingError(""))
  }



  return (isAddressIpCorrect && isGroupingError && isNetworkDeviceNameCorrect)
}
