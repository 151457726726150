import { EMPTY_ERROR_MESSAGE, GENERAL_ERROR_MESSAGES } from "../../../constants/errors"
import {
  setApiAddressIpError,
  setApiInfSystemError, setApiDomainNameError,

} from "../../../store/objects/objectsSlice"
import { validateIp } from "../general/validateIp"
import { IApi } from "../../../store/objects/api/apiTypes"

/**
 * return is the data valid
 * @param {IApi} object   Validation data
 * @param {any} dispatch  Dispatcher to change values in the store
 * @return {boolean}      Is the data valid
 */
export const validateApi = (
  object: IApi,
  dispatch: any,
): boolean => {
  let isAddressIpCorrect
  let isInfSystemCorrect
  let isDomainNameCorrect


  const isAddressIpFormatCorrect = validateIp(object.ip_address)

  if (object.ip_address && !isAddressIpFormatCorrect) {
    dispatch(setApiAddressIpError(GENERAL_ERROR_MESSAGES.IP))

    isAddressIpCorrect = false
  } else {
    dispatch(setApiAddressIpError(EMPTY_ERROR_MESSAGE))

    isAddressIpCorrect = true
  }

  if (object.inf_system?.id === "") {
    dispatch(setApiInfSystemError((GENERAL_ERROR_MESSAGES.POPUP_EMPTY)))
    isInfSystemCorrect = false
  }
  else {
    dispatch(setApiInfSystemError(("")))
    isInfSystemCorrect = true
  }

  if (!object.domain_name) {
    dispatch(setApiDomainNameError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY))

    isDomainNameCorrect = false
  } else {
    dispatch(setApiDomainNameError(EMPTY_ERROR_MESSAGE))

    isDomainNameCorrect = true
  }


  return (isAddressIpCorrect && isInfSystemCorrect && isDomainNameCorrect)
}
