import {
  EMPTY_ERROR_MESSAGE,
  GENERAL_ERROR_MESSAGES,
} from "../../../constants/errors"
import {
  setNetworkDeviceGroupingError,
  setNetworkDeviceAddressIpError,
  setNetworkDeviceWorkTypeError,
  setNetworkDeviceNameError,
} from "../../../store/objects/objectsSlice"
import { validateIp } from "../general/validateIp"
import { INetworkDevice } from "../../../store/objects/networkDevices/networkDevicesTypes"


export const validateNetworkDevice = (
  object: INetworkDevice,
  dispatch: any,
): boolean => {
  let isAddressIpCorrect
  let isNameCorrect
  let isGroupingIsCorrect

  if (object.inf_system?.id === "" && object.office?.id === "") {
    isGroupingIsCorrect = false
    dispatch(setNetworkDeviceGroupingError(GENERAL_ERROR_MESSAGES.POPUP_EMPTY))
  }
  else {
    isGroupingIsCorrect = true
    dispatch(setNetworkDeviceGroupingError(""))
  }

  const isAddressIpFormatCorrect = validateIp(object.ip_address)

  if (object.ip_address && !isAddressIpFormatCorrect) {
    dispatch(setNetworkDeviceAddressIpError(GENERAL_ERROR_MESSAGES.IP))

    isAddressIpCorrect = false
  } else {
    dispatch(setNetworkDeviceAddressIpError(EMPTY_ERROR_MESSAGE))

    isAddressIpCorrect = true
  }

  if (!object.network_device_name) {
    dispatch(setNetworkDeviceNameError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY))

    isNameCorrect = false
  } else {
    dispatch(setNetworkDeviceWorkTypeError(EMPTY_ERROR_MESSAGE))

    isNameCorrect = true
  }

  return (isAddressIpCorrect && isNameCorrect && isGroupingIsCorrect)
}
