import { EMPTY_ERROR_MESSAGE, GENERAL_ERROR_MESSAGES } from "../../../constants/errors"
import {
  setServerGroupingError,
  setServerAddressIpError,
  setNetworkDeviceNameError,
  setServerNameError,
} from "../../../store/objects/objectsSlice"
import { IServer } from "../../../store/objects/servers/serversTypes"
import { validateIp } from "../general/validateIp"

/**
 * return is the data valid
 * @param {IServer} object  Validation data
 * @param {any} dispatch    Dispatcher to change values in the store
 * @return {boolean}        Is the data valid
 */
export const validateServer = (
  object: IServer,
  dispatch: any,
): boolean => {
  let isAddressIpCorrect
  let isNameCorrect
  let isInfSystemsCorrect

  if (object.inf_system?.id === "" && object.office?.id === "") {
    isInfSystemsCorrect = false
    dispatch(setServerGroupingError(GENERAL_ERROR_MESSAGES.FIELD_EMPTY))
  }
  else {
    isInfSystemsCorrect = true
    dispatch(setServerGroupingError(""))
  }

  const isAddressIpFormatCorrect = validateIp(object.ip_address)

  if (object.ip_address && !isAddressIpFormatCorrect) {
    dispatch(setServerAddressIpError(GENERAL_ERROR_MESSAGES.IP))

    isAddressIpCorrect = false
  } else {
    dispatch(setServerAddressIpError(EMPTY_ERROR_MESSAGE))

    isAddressIpCorrect = true
  }

  if (!object.network_device_name) {
    dispatch(setServerNameError(GENERAL_ERROR_MESSAGES.POPUP_EMPTY))

    isNameCorrect = false
  } else {
    dispatch(setServerNameError(EMPTY_ERROR_MESSAGE))

    isNameCorrect = true
  }


  return (isAddressIpCorrect && isNameCorrect && isInfSystemsCorrect)
}
